import React, { useState } from "react";
import Footer from "./footer";
import Header from "./header";
import RequestAccessForm from "./request-access-form";
import Seo from "./seo";
import { useScrollPosition } from "../hooks/useScrollPosition";

export default function Layout(props) {
  const [scroll, setScroll] = useState(0);

  useScrollPosition(function setScrollPosition({ currPos }) {
    setScroll(currPos.y);
  }, []);

  const atTop = scroll === 0;
  console.log(atTop);

  return (
    <div className="min-h-screen min-w-screen overflow-hidden">
      <Seo title={props.title} />
      <Header homePage={props.homePage} atTop={atTop} />
      <div className="pt-20 min-h-screen">{props.children}</div>
      <Footer />
      <RequestAccessForm />
    </div>
  );
}
