/**
 * Get access to the GTM datalayer
 */

let dataLayer: any;

if (typeof window !== "undefined") {
  //@ts-ignore
  dataLayer = window.dataLayer || [];
} else {
  dataLayer = [];
}

export function gtm(event: any) {
  dataLayer.push(event);
}
