import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useSiteMetadata } from "../hooks";

export default function Seo({ title }) {
  const { pathname } = useLocation();
  const { url, title: defaultTitle, description } = useSiteMetadata();
  const pageTitle = title || defaultTitle;

  return (
    <Helmet title={pageTitle}>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={description} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content={`${url}${pathname}`} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://Cotera.co/logo.png" />
    </Helmet>
  );
}
