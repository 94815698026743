import React from "react";
import { Link } from "gatsby";
import { classNames } from "../utils";
import LogoSolid from "../../assets/images/logo-solid.svg";
import LogoWhite from "../../assets/images/logo-white.svg";

export function Logo() {
  return (
    <Link to="/">
      <div className="flex flex-row items-center space-x-2">
        <div className="w-4">
          <LogoSolid />
        </div>
        <div
          className={classNames(
            "w-36 font-bold tracking-tighter text-2xl",
            "text-indigo-500"
          )}
        >
          cotera
        </div>
      </div>
    </Link>
  );
}

export function WhiteLogo() {
  return (
    <Link to="/">
      <div className="flex flex-row items-center space-x-2">
        <div className="w-4">
          <LogoWhite />
        </div>
        <div
          className={classNames(
            "w-36 font-bold tracking-tighter text-2xl",
            "text-white"
          )}
        >
          cotera
        </div>
      </div>
    </Link>
  );
}
